import { authHeader } from './helper/auth-header'
import { authHeaderMultipart } from './helper/auth-header'
import axios from 'axios'

export default {
    methods: {
        kmo_refreshEvents() {
            return this.$http.get('events/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.events = resultArray
            });
        },
        kmo_refreshUsers() {
            return this.$http.get('users/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.databaseUsers = resultArray
            });
        },
        kmo_refreshLanguages() {
            return this.$http.get('texts/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.databaseLanguages = resultArray
            });
        },
        kmo_refreshLiveUsers() {
            return this.$http.get('users/live', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.users = resultArray
            });
        },
        kmo_refreshHeartbeat() {
            return this.$http.get('heartbeat', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.databaseHeartbeat = resultArray
            });
        },
        kmo_refreshUsersPassed() {
            return this.$http.get('users/passed', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.passed = resultArray
            });
        },
        kmo_refreshUsersAwards() {
            return this.$http.get('users/awards', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.awards = resultArray
            });
        },
        kmo_savePassed(passed) {
            return this.$http.post('users/passed/save', JSON.stringify(passed), { headers: authHeaderMultipart })
            .then(response => {
                return response.json()
            });
        },
        kmo_saveAward(awarded) {
            return this.$http.post('users/awards/save', JSON.stringify(awarded), { headers: authHeaderMultipart })
            .then(response => {
                return response.json()
            });
        },
        kmo_checkoutUser(user) {
            return this.$http.post('checkout', JSON.stringify(user), { headers: authHeaderMultipart })
            .then(response => {
                return response.json()
            });
        },
        kmo_refreshLiveStations() {
            return this.$http.get('station/livelist', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.stations = resultArray
            });
        },
        kmo_refreshSurveyComments() {
            return this.$http.get('survey/comments/0/0/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.surveyComments = resultArray
            });
        },
        kmo_stationCheckin(stationKey, active) {
            var activeString = "closecheckin"
            if (active) {
                activeString = "opencheckin"
            }
            return this.$http.get('station/' + activeString + '/' + stationKey, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_refreshMarkets() {
            return this.$http.get('markets/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.markets = resultArray
            });
        },
        kmo_refreshUserCount() {
            return this.$http.get('users/count', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.usersCount = resultArray
            });
        },
        kmo_refreshPassedCount() {
            return this.$http.get('users/passed/count', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.passedCount = resultArray
            });
        },
        kmo_refreshStationRating() {
            return this.$http.get('archive/rating/sum/training-stations', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                this.$store.stationRating = data
            });
        },
        kmo_importUsers(file, wave) {
            var formData = new FormData();
            formData.append('file', file, 'users.xlsx')
            formData.append('wave', wave)
            return this.$http.post('import/users', formData, { headers: authHeaderMultipart })
            .then(response => {
                return response.json()
            });
        },
        kmo_importLanguages(file) {
            var formData = new FormData();
            formData.append('file', file, 'languages.xlsx')
            return this.$http.post('import/languages', formData, { headers: authHeaderMultipart })
            .then(response => {
                return response.json()
            });
        },
        kmo_importSubtitles(file) {
            var formData = new FormData();
            formData.append('file', file, 'subtitles.xlsx')
            return this.$http.post('import/subtitles', formData, { headers: authHeaderMultipart })
            .then(response => {
                return response.json()
            });
        },
        kmo_refreshCoins(eventID) {
            return this.$http.get('tracking/coin/' + eventID, { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.coins = resultArray
            });
        },
        kmo_saveCoinStatus(userID, slot, status) {
            var formData = new FormData();
            formData.append('userID', userID)
            formData.append('slot', slot)
            formData.append('status', status)
            return this.$http.post('tracking/coin', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_searchUser(eventID) {
            return this.$http.get('users/eventID/' + eventID, { headers: authHeader })
            .then(response => {
                return response.json().catch(() => response.text())
            }).then(data => {
                this.$store.user = data
            });
        },
        kmo_resendMail(eventID) {
            return this.$http.get('users/register/send/' + eventID, { headers: authHeader })
            .then(response => {
                return response.json().catch(() => response.text())
            }).then(data => {
                this.$store.user = data
            });
        },
        kmo_refreshRoutes() {
            return this.$http.get('driveroutes/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.routes = resultArray
            });
        },
        kmo_refreshRouteList() {
            return this.$http.get('driveroutes/list', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.routeList = resultArray
            });
        },
        kmo_saveRoute(routeName, eventID) {
            var formData = new FormData();
            formData.append('routeName', routeName)
            formData.append('eventID', eventID)
            return this.$http.post('driveroutes/route/save', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_saveWaypoint(routeID, waypointName, type, lat, lon) {
            var formData = new FormData();
            formData.append('routeID', routeID)
            formData.append('waypointName', waypointName)
            formData.append('type', type)
            formData.append('lat', lat)
            formData.append('lon', lon)
            return this.$http.post('driveroutes/waypoint/save', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_updateWaypoint(waypointID, waypointName, type, lat, lon) {
            var formData = new FormData();
            formData.append('waypointID', waypointID)
            formData.append('waypointName', waypointName)
            formData.append('type', type)
            formData.append('lat', lat)
            formData.append('lon', lon)
            return this.$http.post('driveroutes/waypoint/update', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_moveWaypoint(waypointID, direction) {
            var formData = new FormData();
            formData.append('waypointID', waypointID)
            formData.append('direction', direction)
            return this.$http.post('driveroutes/waypoint/move', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_deleteWaypoint(waypointID) {
            var formData = new FormData();
            formData.append('waypointID', waypointID)
            return this.$http.post('driveroutes/waypoint/delete', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_refreshSpeechList() {
            return this.$http.get('speech/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.speechList = resultArray
            });
        },
        kmo_saveSpeech(vehicleGroupID, speechName, de_DE, en_EN) {
            var formData = new FormData();
            formData.append('vehicleGroupID', vehicleGroupID)
            formData.append('speechName', speechName)
            formData.append('de_DE', de_DE)
            formData.append('en_EN', en_EN)
            return this.$http.post('speech/save', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_updateSpeech(speechKey, vehicleGroupID, speechName, de_DE, en_EN) {
            var formData = new FormData();
            formData.append('speechKey', speechKey)
            formData.append('vehicleGroupID', vehicleGroupID)
            formData.append('speechName', speechName)
            formData.append('de_DE', de_DE)
            formData.append('en_EN', en_EN)
            return this.$http.post('speech/update', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_uploadSpeech(speechKey, languageKey, file) {
            var formData = new FormData();
            formData.append('speechKey', speechKey)
            formData.append('languageKey', languageKey)
            formData.append('file', file)
            return this.$http.post('speech/upload', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_playSpeech(filename) {
            return axios.get(this.$http.options.root + 'speech/download/' + filename, {
                responseType: 'blob',
                headers: authHeader
            })
        },
        kmo_deleteSpeech(speechKey, languageKey) {
            var formData = new FormData();
            formData.append('speechKey', speechKey)
            formData.append('languageKey', languageKey)
            return this.$http.post('speech/delete', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_generateSpeech(speechKey, languageKey, text) {
            var formData = new FormData();
            formData.append('speechKey', speechKey)
            formData.append('languageKey', languageKey)
            formData.append('text', text)
            return this.$http.post('speech/generate', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_generateSpeechInfo() {
            var formData = new FormData();
            formData.append('infoType', 'user')
            return this.$http.post('speech/info', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_refreshGeofencing() {
            return this.$http.get('geofencing/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.poiList = resultArray
            });
        },
        kmo_savePOI(poiName, triggerType, contentKeys, lat, lon, radius) {
            var formData = new FormData();
            formData.append('poiName', poiName)
            formData.append('triggerType', triggerType)
            formData.append('contentKeys', contentKeys)
            formData.append('lat', lat)
            formData.append('lon', lon)
            formData.append('radius', radius)
            return this.$http.post('geofencing/save', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_updatePOI(poiID, poiName, triggerType, contentKeys, lat, lon, radius) {
            var formData = new FormData();
            formData.append('poiID', poiID)
            formData.append('poiName', poiName)
            formData.append('triggerType', triggerType)
            formData.append('contentKeys', contentKeys)
            formData.append('lat', lat)
            formData.append('lon', lon)
            formData.append('radius', radius)
            return this.$http.post('geofencing/update', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },        
        kmo_poiMappings(routeID) {
            return this.$http.get('geofencing/mapping/' + routeID, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_poiMappingSave(routeID, poiIDs) {
            var formData = new FormData();
            formData.append('routeID', routeID)
            formData.append('poiIDs', poiIDs)
            return this.$http.post('geofencing/mapping/save', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_vehicleMappings(routeID) {
            return this.$http.get('vehicle/mapping/' + routeID, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_vehicleMappingSave(routeID, vehicleGroupIDs) {
            var formData = new FormData();
            formData.append('routeID', routeID)
            formData.append('vehicleGroupIDs', vehicleGroupIDs)
            return this.$http.post('vehicle/mapping/save', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_refreshCoinList() {
            return this.$http.get('tracking/allcoins', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.coinList = resultArray
            });
        },
        kmo_refreshPassed() {
            return this.$http.get('tracking/passed/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.passedCount = resultArray
            });
        },
        kmo_refreshCoinSum() {
            return this.$http.get('tracking/coinsum', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.coinSum = resultArray
            });
        },
        kmo_refreshSlotTimes() {
            return this.$http.get('ranking/times/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.slotTimes = resultArray
            });
        },
        kmo_refreshAnswers() {
            return this.$http.get('ranking/points/sum', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.answers = resultArray
            });
        },
        kmo_saveTextUpdate(textKey, de_DE, en_EN) {
            var formData = new FormData();
            formData.append('textKey', textKey)
            formData.append('de_DE', de_DE)
            formData.append('en_EN', en_EN)
            return this.$http.post('texts/update', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            });
        },
        kmo_refreshVehicles() {
            return this.$http.get('vehicles/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.vehicles = resultArray
            });
        },
        kmo_refreshVehicleGroups() {
            return this.$http.get('vehicles/groups/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.vehicleGroups = resultArray
            });
        },
        kmo_refreshConsents() {
            return this.$http.get('tracking/consent/slot/safety-consent', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.consents = resultArray
            });
        },
        kmo_refreshPoiList() {
            return this.$http.get('geofencing/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.allPois = resultArray
            });
        },
        kmo_refreshReachedPoiList() {
            return this.$http.get('geofencing/reachedpoi/all', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.allReachedPois = resultArray
            });
        },
        kmo_refreshSkippedTracks() {
            return this.$http.get('tracking/musicskip/top', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.skippedTracks = resultArray
            });
        },
        kmo_refreshFeedback() {
            return this.$http.get('ratings/feedback', { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.feedbacks = resultArray
            });
        },
        kmo_refreshResults(quizGroup, questionGroup) {
            var formData = new FormData();
            formData.append('quizGroup', quizGroup)
            formData.append('questionGroup', questionGroup)
            return this.$http.post('quiz/results', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.quizResults = resultArray
            });
        },
        kmo_refreshStationStatus(stationID) {
            return this.$http.get('stations/status/' + stationID, { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                this.$store.serverStatus = data
            });
        },
        kmo_refreshStationConfig(stationID) {
            return this.$http.get('stations/config/' + stationID, { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                this.$store.serverConfig = data
            });
        },
        kmo_refreshLeaderboardMarketPoints(quizGroup, questionGroup) {
            var formData = new FormData();
            formData.append('quizGroup', quizGroup)
            formData.append('questionGroup', questionGroup)
            return this.$http.post('quiz/leaderboard/marketpoints', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.leaderboardMarketPoints = resultArray
            });
        },
        kmo_refreshLeaderboardJobrolePoints(quizGroup, questionGroup) {
            var formData = new FormData();
            formData.append('quizGroup', quizGroup)
            formData.append('questionGroup', questionGroup)
            return this.$http.post('quiz/leaderboard/jobrolepoints', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.leaderboardJobrolePoints = resultArray
            });
        },
        kmo_refreshLeaderboardMarketSpeed(quizGroup, questionGroup) {
            var formData = new FormData();
            formData.append('quizGroup', quizGroup)
            formData.append('questionGroup', questionGroup)
            return this.$http.post('quiz/leaderboard/marketspeed', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.leaderboardMarketSpeed = resultArray
            });
        },
        kmo_refreshLeaderboardJobroleSpeed(quizGroup, questionGroup) {
            var formData = new FormData();
            formData.append('quizGroup', quizGroup)
            formData.append('questionGroup', questionGroup)
            return this.$http.post('quiz/leaderboard/jobrolespeed', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.leaderboardJobroleSpeed = resultArray
            });
        },
        kmo_refreshLeaderboardAllCorrect(quizGroup, questionGroup) {
            var formData = new FormData();
            formData.append('quizGroup', quizGroup)
            formData.append('questionGroup', questionGroup)
            return this.$http.post('quiz/leaderboard/allcorrect', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.leaderboardAllCorrect = resultArray
            });
        },
        kmo_refreshLeaderboardBestUsers(quizGroup, questionGroup) {
            var formData = new FormData();
            formData.append('quizGroup', quizGroup)
            formData.append('questionGroup', questionGroup)
            return this.$http.post('quiz/leaderboard/bestusers', formData, { headers: authHeader })
            .then(response => {
                return response.json()
            }).then(data => {
                const resultArray = [];
                for (let key in data) {
                    resultArray.push(data[key]);
                }
                this.$store.leaderboardBestUsers = resultArray
            });
        },
        kmo_resetQuizAnswers() {
            return this.$http.get('quiz/resetanswers', { headers: authHeader })
            .then(response => {
                return response
            });
        },
        kmo_resetStationStatus() {
            return this.$http.get('quiz/resetstatus', { headers: authHeader })
            .then(response => {
                return response
            });
        },
    }
};