import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueResource from 'vue-resource'
import VueFriendlyIframe from 'vue-friendly-iframe'
import App from './App.vue'

import { store } from './store/store'
import Service from './Service'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueResource)
Vue.use(VueFriendlyIframe)
Vue.http.options.root = 'https://cupra.oua.kamino-api.net:7284/'
// Vue.http.options.root = 'http://127.0.0.1:7284/'

Vue.mixin(Service)

new Vue({
  el: '#app',
  store: store,
  render: h => h(App)
})
