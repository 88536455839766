<template>
    <div>

        <div class="header" style="float: left">{{ currentCategory }}</div>
        <b-form-select v-model="selectedMarket" :options="possibleMarkets" style="width: 190px; float: right; margin-bottom: 10px" @change="filterMarket()"></b-form-select>
        <div style="clear: both">
            <div class="header" style="float: right">{{ userCount }} - {{ participantsText }}</div>
            <div class="headersmall" style="float: left">{{ currentQuestionCount }}</div>
        </div>
        <div style="clear: both">
            <Bar
                id="chart"
                :chart-options="chartOptions"
                :chart-data="chartData"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
            /><br />
            <b-form-input v-model="filter" type="search" size="lg" placeholder="Search" style="margin-bottom: 10px;"></b-form-input>
            <b-table class="textTable" :items="filteredResultList" :fields="fields" :filter="filter" responsive bordered outlined small sort-icon-left hover>
                <template #cell(marketName)="data">
                    <b v-if="data.value == 'International'"><b-img id="flag" src="flags/INT.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Australia'"><b-img id="flag" src="flags/AU.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Austria'"><b-img id="flag" src="flags/AT.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Belgium'"><b-img id="flag" src="flags/BE.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Colombia'"><b-img id="flag" src="flags/CO.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Croatia'"><b-img id="flag" src="flags/HR.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Czech Republic'"><b-img id="flag" src="flags/CZ.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Denmark'"><b-img id="flag" src="flags/DK.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Finland'"><b-img id="flag" src="flags/FI.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'France'"><b-img id="flag" src="flags/FR.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Germany'"><b-img id="flag" src="flags/DE.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Hungary'"><b-img id="flag" src="flags/HU.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Ireland'"><b-img id="flag" src="flags/IE.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Italy'"><b-img id="flag" src="flags/IT.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Mexico'"><b-img id="flag" src="flags/MX.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Morocco'"><b-img id="flag" src="flags/MA.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Netherlands'"><b-img id="flag" src="flags/NL.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Poland'"><b-img id="flag" src="flags/PL.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Portugal'"><b-img id="flag" src="flags/PT.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Romania'"><b-img id="flag" src="flags/RO.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Slovakia'"><b-img id="flag" src="flags/SK.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Slovenia'"><b-img id="flag" src="flags/SI.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Spain'"><b-img id="flag" src="flags/ES.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Sweden'"><b-img id="flag" src="flags/SE.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Switzerland'"><b-img id="flag" src="flags/CH.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Tunisia'"><b-img id="flag" src="flags/TN.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'Turkey'"><b-img id="flag" src="flags/TR.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <b v-else-if="data.value == 'United Kingdom'"><b-img id="flag" src="flags/UK.png" style="padding-right:10px"></b-img>{{ data.value }}</b>
                    <span v-else>{{ data.value }}</span>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: {
        Bar
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            timer: '',
            currentCategory: "",
            currentQuestionCount: "",
            userCount: "",
            filter: null,
            participantsText: "",
            resultList: [],
            filteredResultList: [],
            userNames: [],
            userCorrect: [],
            userIncorrect: [],
            userPoints: [],
            reachedPoiList: [],
            poiIDList: [],
            poiCountList: [],
            chartData: {},
            selectedMarket: 'all',
            possibleMarkets: [
                { value: 'all', text: 'All Markets'},
            ],
            fields: [
                { key: 'rank', label: '#', sortable: true },
                { key: 'user', label: 'Participant', sortable: true },
                { key: 'marketName', label: 'Market', sortable: true },
                { key: 'jobroleName', label: 'Jobrole', sortable: true },
                { key: 'correctString', label: 'Correct', sortable: true },
                { key: 'correctPercent', label: 'Percent', sortable: true },
                { key: 'points', label: 'Points', sortable: true },
                { key: 'timeString', label: 'Time', sortable: true },
            ],
            chartOptions: {
                responsive: true, 
                maintainAspectRatio: false,
                scales: {
                    y: {
                        title: {
                            color: 'black',
                            display: true,
                            text: 'Answers'
                        },
                        stacked: true,
                        min: 0,
                        ticks: {
                            stepSize: 1
                        }
                    },
                    x: {
                        title: {
                            color: 'black',
                            display: true,
                            text: ''
                        },
                        stacked: true,
                    }
                }
            }
        }
    },
    created() {
        this.refreshRanking()
        this.timer = setInterval(this.triggeredAutoUpdater, 5000)
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    methods: {
        triggeredAutoUpdater() {
            this.refreshRanking()
        },
        refreshRanking() {
            this.kmo_refreshStationStatus("ts101").then(() => {

                var quizGroup = "0"
                var questionGroup = "0"
                this.currentCategory = "Waiting for Start"
                this.currentQuestionCount = ""
                switch (this.$store.serverStatus) {
                    case 1:
                        quizGroup = "brand"
                        questionGroup = "sponsorship"
                        this.currentCategory = "Brand | Sponsorship"
                        this.currentQuestionCount = "5 Questions"
                        break;
                    case 2:
                        quizGroup = "brand"
                        questionGroup = "partnership"
                        this.currentCategory = "Brand | Partnership"
                        this.currentQuestionCount = "5 Questions"
                        break;
                    case 3:
                        quizGroup = "brand"
                        questionGroup = "range"
                        this.currentCategory = "Brand | Range"
                        this.currentQuestionCount = "5 Questions"
                        break;
                    case 4:
                        quizGroup = "brand"
                        questionGroup = "values"
                        this.currentCategory = "Brand | Values"
                        this.currentQuestionCount = "5 Questions"
                        break;
                    case 5:
                        quizGroup = "brand"
                        questionGroup = "others"
                        this.currentCategory = "Brand | Others"
                        this.currentQuestionCount = "5 Questions"
                        break;
                    case 6:
                        quizGroup = "mini-game"
                        questionGroup = "mini-game"
                        this.currentCategory = "Mini-Game"
                        this.currentQuestionCount = "5 Level"
                        break;
                    case 7:
                        quizGroup = "theoretical"
                        questionGroup = ""
                        this.currentCategory = "Individual"
                        this.currentQuestionCount = "30 Questions"
                        break;
                    case 8:
                        quizGroup = "all"
                        questionGroup = ""
                        this.currentCategory = "Overall"
                        this.currentQuestionCount = "55 Questions"
                        break;
                }

                this.kmo_refreshResults(quizGroup, questionGroup).then(() => {

                    this.resultList = this.$store.quizResults

                    var allMarkets = []
                    this.resultList.forEach(result => {
                        allMarkets.push(result.market)
                    })

                    this.possibleMarkets = [ { value: 'all', text: 'All Markets'} ]
                    let filterStringSet = new Set(allMarkets)
                    filterStringSet.forEach(market => {
                        this.possibleMarkets.push( { value: market, text: this.marketflagForID(market) + " " + this.marketnameForID(market) } )
                    })
                    this.filterMarket()
                })
            })
        },
        filterMarket() {
            if (this.selectedMarket == "all") {
                this.filteredResultList = this.resultList
            } else {
                this.filteredResultList = []
                this.resultList.forEach(result => {
                    if (result.market == this.selectedMarket) {
                        this.filteredResultList.push(result)
                    }
                })
            }
            this.buildChart()
        },
        buildChart() {

            this.userNames = []
            this.userCorrect = []
            this.userIncorrect = []
            this.userPoints = []

            var chartResults = []
            if (this.selectedMarket == "all") {
                chartResults = this.resultList
            } else {
                this.resultList.forEach(result => {
                    if (result.market == this.selectedMarket) {
                        chartResults.push(result)
                    }
                })
            }

            this.participantsText = "Participants"
            this.userCount = chartResults.length
            if (this.userCount == 1) {
                this.participantsText = "Participant"
            }

            var rankCounter = 1
            chartResults.forEach(result => {

                this.userNames.push(rankCounter)
                // this.userNames.push(this.marketflagForID(result.market))
                this.userCorrect.push(result.correct)
                this.userIncorrect.push(result.incorrect)
                this.userPoints.push(result.points)

                result.rank = ""+rankCounter++
                // result.marketName = this.marketflagForID(result.market) + " " + this.marketnameForID(result.market)
                result.marketName = this.marketnameForID(result.market)
                result.jobroleName = this.jobroleNameForID(result.jobrole)

                result.user = result.firstname + " " + result.lastname
                let userAmount = result.correct + result.incorrect
                result.correctString = result.correct + "/" + userAmount
                if (this.$store.serverStatus < 6) {
                    result.correctPercent = Math.round((result.correct * 100) / 5) + "%"
                }
                if (this.$store.serverStatus == 6) {
                    result.correctPercent = Math.round((result.correct * 100) / 5) + "%"
                }
                if (this.$store.serverStatus == 7) {
                    result.correctPercent = Math.round((result.correct * 100) / 30) + "%"
                }
                if (this.$store.serverStatus == 8) {
                    result.correctPercent = Math.round((result.correct * 100) / 55) + "%"
                }
                result.timeString = result.time.toFixed(3) + " sec."
            })

            this.chartData = {
                labels: this.userNames,
                datasets: [
                    {
                        label: 'Correct',
                        backgroundColor: '#350a76',
                        data: this.userCorrect
                    },
                    {
                        label: 'Incorrect',
                        backgroundColor: '#EFEFEF',
                        data: this.userIncorrect
                    },
                ]
            }
        },
        marketnameForID(marketID) {
            switch (marketID) {
                case "INT": return "International"
                case "AU": return "Australia"
                case "AT": return "Austria"
                case "BE": return "Belgium"
                case "CO": return "Colombia"
                case "HR": return "Croatia"
                case "CZ": return "Czech Republic"
                case "DK": return "Denmark"
                case "FI": return "Finland"
                case "FR": return "France"
                case "DE": return "Germany"
                case "HU": return "Hungary"
                case "IE": return "Ireland"
                case "IT": return "Italy"
                case "MX": return "Mexico"
                case "MA": return "Morocco"
                case "NL": return "Netherlands"
                case "PL": return "Poland"
                case "PT": return "Portugal"
                case "RO": return "Romania"
                case "SK": return "Slovakia"
                case "SI": return "Slovenia"
                case "ES": return "Spain"
                case "SE": return "Sweden"
                case "CH": return "Switzerland"
                case "TN": return "Tunisia"
                case "TR": return "Turkey"
                case "UK": return "United Kingdom"
            }
        },
        marketflagForID(marketID) {
            switch (marketID) {
                case "INT": return "🇪🇺"
                case "AU": return "🇦🇺"
                case "AT": return "🇦🇹"
                case "BE": return "🇧🇪"
                case "CO": return "🇨🇴"
                case "HR": return "🇭🇷"
                case "CZ": return "🇨🇿"
                case "DK": return "🇩🇰"
                case "FI": return "🇫🇮"
                case "FR": return "🇫🇷"
                case "DE": return "🇩🇪"
                case "HU": return "🇭🇺"
                case "IE": return "🇮🇪"
                case "IT": return "🇮🇹"
                case "MX": return "🇲🇽"
                case "MA": return "🇲🇦"
                case "NL": return "🇳🇱"
                case "PL": return "🇵🇱"
                case "PT": return "🇵🇹"
                case "RO": return "🇷🇴"
                case "SK": return "🇸🇰"
                case "SI": return "🇸🇮"
                case "ES": return "🇪🇸"
                case "SE": return "🇸🇪"
                case "CH": return "🇨🇭"
                case "TN": return "🇹🇳"
                case "TR": return "🇹🇷"
                case "UK": return "🇬🇧"
            }
        },
        jobroleNameForID(jobroleID) {
            switch (jobroleID) {
                case "CM": return "CUPRA Master"
                case "CE": return "CUPRA Expert"
                case "SM": return "Sales Manager"
                case "SEM": return "Service Manager"
                case "MT": return "Master Technician"
            }
        }
    }
}
</script>

<style>
#chart {
    background-color: white;
}
#color {
    background-color: #350a76;
    font-size: 13px;
}
.textTable {
    background-color: white;
    font-family: "Cupra-Regular" !important;
    font-size: 16px !important;
    color: white;
}
.header {
    font-family: "Cupra-Medium" !important;
    font-size: 30px !important;
    color: white;
    margin-bottom: 10px
}
.headersmall {
    font-family: "Cupra-Medium" !important;
    font-size: 20px !important;
    color: white;
    margin-bottom: 10px
}
#flag {
    height: 20px
}
</style>