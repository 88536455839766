<template>
    <div id="background">
        <!-- <header-nav @showComponent="showComponent()"></header-nav>
        <br /><br /> -->
        <component :is="selectedComponent" style="margin-top: 20px; margin-left: 20px; margin-right: 20px;"></component>
    </div>
</template>

<script>
import Header from './components/Header'
import Leaderboard from './components/Leaderboard'
import Day1Ranking from './components/Day1Ranking'
import DatabaseLanguages from './components/database/DatabaseLanguages'
import Settings from './components/Settings'

export default {
    data () {
        return {
            selectedComponent: "leaderboard"
        }
    },
    methods: {
        showComponent() {
            this.selectedComponent = this.$store.activeComponent
        }
    },
	components: {
        'header-nav': Header,
        'leaderboard': Leaderboard,
        'day1-ranking': Day1Ranking,
        'database-languages': DatabaseLanguages,
        'settings': Settings
	}
}
</script>

<style>
html, body, #background {
    background-color: transparent;
}
body {
  background: url("background.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@font-face {
  font-family: "Cupra-Bold";
  font-display: auto;
  src: url(./fonts/Cupra-Bold.ttf) format("truetype")
}
@font-face {
  font-family: "Cupra-Regular";
  font-display: auto;
  src: url(./fonts/Cupra-Regular.ttf) format("truetype")
}
@font-face {
  font-family: "Cupra-Medium";
  font-display: auto;
  src: url(./fonts/Cupra-Medium.ttf) format("truetype")
}
</style>
