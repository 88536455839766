<template>
    <div class="fixed-top">
        <b-navbar toggleable="lg" type="dark" variant="dark">
            <b-navbar-brand class="navheader" href="#" >CUPRA OUA 2024 Backend</b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse class="navelements" id="nav-collapse" is-nav>
                <!-- <b-navbar-nav>
                    <b-nav-item @click="showComponent('routes')">Routen</b-nav-item>
                </b-navbar-nav> -->
                <b-navbar-nav class="ml-auto">
                    <b-nav-item @click="showComponent('leaderboard')">Leaderboard</b-nav-item>
                    <b-nav-item-dropdown text="Admin" right>
                        <b-dropdown-item @click="showComponent('day1-ranking')">Day 1 Ranking</b-dropdown-item>
                        <b-dropdown-item @click="showComponent('database-languages')">App Text</b-dropdown-item>
                        <b-dropdown-item @click="showComponent('settings')">Settings</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <!-- <b-nav-item-dropdown text="Database" right>
                        <b-dropdown-item @click="showComponent('database-languages')">Texts</b-dropdown-item>
                    </b-nav-item-dropdown> -->
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
export default {
    methods: {
        showComponent(component) {
            this.$store.activeComponent = component
            this.$emit('showComponent')
        }
    }
}
</script>

<style>
.navbar.navbar-dark.bg-dark{
    background-color: #350a76 !important;
 }
 .navheader {
    font-family: "Cupra-Medium" !important;
    font-size: 20px !important;
    color: white;
}
.navelements {
    font-family: "Cupra-Regular" !important;
    font-size: 16px !important;
    color: white;
}
</style>