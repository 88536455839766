<template>
    <div id="container">
        <div><b-img src="cupra-logo2.png"></b-img></div>
        <div id="content">
            <div><b-img src="cupra-logo.png" style="display: block; margin: auto;"></b-img></div>
            <div id="header" style="text-align: center;">{{ headerText }}</div>
            <div v-if=showSubline class="info" style="text-align: center;">{{ infoText }}</div>
            <div v-if=showCountdown2 class="counter2">{{ countSeconds2 }}</div>
            <div v-if=showCountdown class="counter">{{ countMinutes }}:{{ countSeconds }}</div>
            <div v-if=showLeaderboards>
                <div class="rankContainer" v-if=showLeaderBoardMarketPoints>
                    <div class="ranking">
                        <div v-for="(result, i) in rankMarketPointsList" :key="i">
                            <div style="clear: both; margin-top: 60px">
                                <div class="bubble" style="float: left; margin-right: 50px; margin-bottom: 30px">{{ i+1 }}</div>
                                <div class="textblock" style="float: left">{{ result.market }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rankContainer" v-if=showLeaderBoardJobrolePoints>
                    <div class="ranking">
                        <div v-for="(result, i) in rankJobrolePointsList" :key="i">
                            <div style="clear: both; margin-top: 60px">
                                <div class="bubble" style="float: left; margin-right: 50px; margin-bottom: 30px">{{ i+1 }}</div>
                                <div class="textblock" style="float: left">{{ result.jobrole }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rankContainer" v-if=showLeaderBoardMarketSpeed>
                    <div class="ranking">
                        <div v-for="(result, i) in rankMarketSpeedList" :key="i">
                            <div style="clear: both; margin-top: 60px">
                                <div class="bubble" style="float: left; margin-right: 50px; margin-bottom: 30px">{{ i+1 }}</div>
                                <div class="textblock" style="float: left">{{ result.market }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rankContainer" v-if=showLeaderBoardJobroleSpeed>
                    <div class="ranking">
                        <div v-for="(result, i) in rankJobroleSpeedList" :key="i">
                            <div style="clear: both; margin-top: 60px">
                                <div class="bubble" style="float: left; margin-right: 50px; margin-bottom: 30px">{{ i+1 }}</div>
                                <div class="textblock" style="float: left">{{ result.jobrole }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rankContainer" v-if=showLeaderBoardAllCorrect>
                    <div class="ranking">
                        <div class="allCorrect">{{ rankAllCorrectList.length }}</div>
                    </div>
                </div>
                <div class="rankContainer" v-if=showLeaderBoardBestUsers>
                    <div class="ranking">
                        <div v-for="(result, i) in rankBestUsers" :key="i">
                            <div style="clear: both; margin-top: 60px">
                                <div class="textblock2">{{ result.user }}<br /><br />{{ marketnameForID(result.market) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            timer: '',
            headerText: '',
            infoText: '',
            showCountdown: false,
            showCountdown2: false,
            isCounting: false,
            timerCountdown: '',
            countDown: 10,
            countMinutes: "0",
            countSeconds: "0",
            countSeconds2: 3,
            quizGroup: '',
            questionGroup: '',
            serverConfig: '',
            lastServerStatus: 0,
            rankMarketPointsList: [],
            rankJobrolePointsList: [],
            rankMarketSpeedList: [],
            rankJobroleSpeedList: [],
            rankAllCorrectList: [],
            rankBestUsers: [],
            showSubline: false,
            showLeaderboards: false,
            showLeaderBoardMarketPoints: false,
            showLeaderBoardJobrolePoints: false,
            showLeaderBoardMarketSpeed: false,
            showLeaderBoardJobroleSpeed: false,
            showLeaderBoardAllCorrect: false,
            showLeaderBoardBestUsers: false,
        }
    },
    created() {
        this.refreshStationStatus()
        this.timer = setInterval(this.triggeredAutoUpdater, 3000)
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    methods: {
        countDownTimer() {
            this.timerCountdown = setInterval(this.countDownSeconds, 1000)
        },
        countDownSeconds() {
            if (this.countDown > 0) {
                this.countDown -= 1
                this.countMinutes = Math.floor(this.countDown / 60)
                this.countSeconds = this.countDown - this.countMinutes * 60
                this.countSeconds = ""+((this.countSeconds < 10 ? '0' : '') + this.countSeconds)
            } else {
                clearInterval(this.timerCountdown)
                this.timerCountdown = ''
            }
        },
        triggeredAutoUpdater() {
            this.refreshStationStatus()
        },
        refreshStationStatus() {

            this.kmo_refreshStationConfig("ts101").then(() => {

                this.serverConfig = this.$store.serverConfig

                this.kmo_refreshStationStatus("ts101").then(() => {

                    switch (this.$store.serverStatus) {
                        case 0:
                            this.headerText = "ONE OF US AWARDS"
                            this.infoText = "Brand & Individual Test"
                            this.quizGroup = "brand"
                            this.questionGroup = ""
                            this.showType("start")
                            break;
                        case 1:
                            this.headerText = "ONE OF US AWARDS"
                            this.infoText = "Brand Test | Sponsorship"
                            this.quizGroup = "brand"
                            this.questionGroup = "sponsorship"
                            this.showType("marketPoints")
                            break;
                        case 2:
                            this.headerText = "ONE OF US AWARDS"
                            this.infoText = "Brand Test | Partnership"
                            this.quizGroup = "brand"
                            this.questionGroup = "partnership"
                            this.showType("jobrolePoints")
                            break;
                        case 3:
                            this.headerText = "ONE OF US AWARDS"
                            this.infoText = "Brand Test | Range"
                            this.quizGroup = "brand"
                            this.questionGroup = "range"
                            this.showType("marketSpeed")
                            break;
                        case 4:
                            this.headerText = "ONE OF US AWARDS"
                            this.infoText = "Brand Test | Values"
                            this.quizGroup = "brand"
                            this.questionGroup = "values"
                            this.showType("jobroleSpeed")
                            break;
                        case 5:
                            this.headerText = "ONE OF US AWARDS"
                            this.infoText = "Brand Test | Final Round"
                            this.quizGroup = "brand"
                            this.questionGroup = "others"
                            this.showType("allCorrect")
                            break;
                        case 6:
                            this.headerText = "ONE OF US AWARDS"
                            this.infoText = "Mini Game"
                            this.quizGroup = "mini-game"
                            this.questionGroup = "mini-game"
                            this.showType("bestUsers")
                            break;
                        case 7:
                            this.headerText = "ONE OF US AWARDS"
                            this.infoText = "Individual Test"
                            this.quizGroup = "theoretical"
                            this.questionGroup = ""
                            this.showType("marketPoints")
                            break;
                        case 8:
                            this.headerText = "ONE OF US AWARDS"
                            this.infoText = "Thanks for your participation"
                            this.quizGroup = "all"
                            this.questionGroup = ""
                            this.showType("start")
                            break;
                    }
                    this.refreshLeaderboardsPoints()
                })
            })
        },
        refreshLeaderboardsPoints() {
            this.kmo_refreshLeaderboardMarketPoints(this.quizGroup, this.questionGroup).then(() => {
                this.rankMarketPointsList = this.$store.leaderboardMarketPoints.slice(0,3)
                this.rankMarketPointsList.forEach(rank => {
                    rank.market = this.marketnameForID(rank.market)
                })
            })

            this.kmo_refreshLeaderboardJobrolePoints(this.quizGroup, this.questionGroup).then(() => {
                this.rankJobrolePointsList = this.$store.leaderboardJobrolePoints.slice(0,3)
                this.rankJobrolePointsList.forEach(rank => {
                    rank.jobrole = this.jobroleNameForID(rank.jobrole)
                })
            })

            this.kmo_refreshLeaderboardMarketSpeed(this.quizGroup, this.questionGroup).then(() => {
                this.rankMarketSpeedList = this.$store.leaderboardMarketSpeed.slice(0,3)
                this.rankMarketSpeedList.forEach(rank => {
                    rank.market = this.marketnameForID(rank.market)
                })
            })

            this.kmo_refreshLeaderboardJobroleSpeed(this.quizGroup, this.questionGroup).then(() => {
                this.rankJobroleSpeedList = this.$store.leaderboardJobroleSpeed.slice(0,3)
                this.rankJobroleSpeedList.forEach(rank => {
                    rank.jobrole = this.jobroleNameForID(rank.jobrole)
                })
            })

            if (this.quizGroup == "brand") {
                this.kmo_refreshLeaderboardAllCorrect(this.quizGroup, this.questionGroup).then(() => {
                    this.rankAllCorrectList = this.$store.leaderboardAllCorrect
                })
            }

            this.kmo_refreshLeaderboardBestUsers(this.quizGroup, this.questionGroup).then(() => {
                this.rankBestUsers = this.$store.leaderboardBestUsers.slice(0,1)
                this.rankBestUsers.forEach(rank => {
                    rank.user = rank.firstname + " " + rank.lastname
                })
            })
        },
        showType(type) {

            if (this.serverConfig == 0) {
                this.showLeaderboards = false
                this.showSubline = true
                if (this.$store.serverStatus > 0) {

                    if (this.lastServerStatus != this.$store.serverStatus) {
                        this.isCounting = false
                        switch (this.$store.serverStatus) {
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                this.countDown = 240 //90
                                break;
                            case 7:
                                this.countDown = 1200 //300
                                break;
                        }
                        this.countMinutes = Math.floor(this.countDown / 60)
                        this.countSeconds = "00"
                    }
                    if (this.isCounting == false) {
                        this.lastServerStatus = this.$store.serverStatus
                        if (this.$store.serverStatus != 6 && this.$store.serverStatus != 8) {
                            this.showCountdown2 = true
                            let count = 3
                            this.countSeconds2 = count
                            const timer = setInterval(function() {
                                count--
                                this.countSeconds2 = count
                                if (count === 0) {
                                    this.showCountdown2 = false
                                    this.showCountdown = true
                                    this.isCounting = true
                                    clearInterval(timer)
                                    clearInterval(this.timerCountdown)
                                    this.timerCountdown = ''
                                    this.countDownTimer()
                                }
                            }.bind(this), 1000)
                        }
                    }
                }
                return
            }
            if (this.serverConfig == 1) {
                this.showLeaderboards = true
                this.showSubline = false
                this.showCountdown = false
            }

            switch (type) {
                case "start":
                    this.showLeaderBoardMarketPoints = false
                    this.showLeaderBoardJobrolePoints = false
                    this.showLeaderBoardMarketSpeed = false
                    this.showLeaderBoardJobroleSpeed = false
                    this.showLeaderBoardAllCorrect = false
                    this.showLeaderBoardBestUsers = false
                    break;
                case "marketPoints":
                    this.headerText = "BEST MARKETS"
                    this.showLeaderBoardMarketPoints = true
                    this.showLeaderBoardJobrolePoints = false
                    this.showLeaderBoardMarketSpeed = false
                    this.showLeaderBoardJobroleSpeed = false
                    this.showLeaderBoardAllCorrect = false
                    this.showLeaderBoardBestUsers = false
                    break;
                case "jobrolePoints":
                    this.headerText = "BEST JOBROLES"
                    this.showLeaderBoardMarketPoints = false
                    this.showLeaderBoardJobrolePoints = true
                    this.showLeaderBoardMarketSpeed = false
                    this.showLeaderBoardJobroleSpeed = false
                    this.showLeaderBoardAllCorrect = false
                    this.showLeaderBoardBestUsers = false
                    break;
                case "marketSpeed":
                    this.headerText = "FASTEST CORRECT ANSWERS"
                    this.showLeaderBoardMarketPoints = false
                    this.showLeaderBoardJobrolePoints = false
                    this.showLeaderBoardMarketSpeed = true
                    this.showLeaderBoardJobroleSpeed = false
                    this.showLeaderBoardAllCorrect = false
                    this.showLeaderBoardBestUsers = false
                    break;
                case "jobroleSpeed":
                    this.headerText = "FASTEST CORRECT ANSWERS"
                    this.showLeaderBoardMarketPoints = false
                    this.showLeaderBoardJobrolePoints = false
                    this.showLeaderBoardMarketSpeed = false
                    this.showLeaderBoardJobroleSpeed = true
                    this.showLeaderBoardAllCorrect = false
                    this.showLeaderBoardBestUsers = false
                    break;
                case "allCorrect":
                    this.headerText = "HOW MANY SCORED 5/5"
                    this.showLeaderBoardMarketPoints = false
                    this.showLeaderBoardJobrolePoints = false
                    this.showLeaderBoardMarketSpeed = false
                    this.showLeaderBoardJobroleSpeed = false
                    this.showLeaderBoardAllCorrect = true
                    this.showLeaderBoardBestUsers = false
                    break;
                case "bestUsers":
                    this.headerText = "TOP PERFORMER"
                    this.showLeaderBoardMarketPoints = false
                    this.showLeaderBoardJobrolePoints = false
                    this.showLeaderBoardMarketSpeed = false
                    this.showLeaderBoardJobroleSpeed = false
                    this.showLeaderBoardAllCorrect = false
                    this.showLeaderBoardBestUsers = true
                    break;
            }
        },
        marketnameForID(marketID) {
            switch (marketID) {
                case "INT": return "International"
                case "AU": return "Australia"
                case "AT": return "Austria"
                case "BE": return "Belgium"
                case "CO": return "Colombia"
                case "HR": return "Croatia"
                case "CZ": return "Czech Republic"
                case "DK": return "Denmark"
                case "FI": return "Finland"
                case "FR": return "France"
                case "DE": return "Germany"
                case "HU": return "Hungary"
                case "IE": return "Ireland"
                case "IT": return "Italy"
                case "MX": return "Mexico"
                case "MA": return "Morocco"
                case "NL": return "Netherlands"
                case "PL": return "Poland"
                case "PT": return "Portugal"
                case "RO": return "Romania"
                case "SK": return "Slovakia"
                case "SI": return "Slovenia"
                case "ES": return "Spain"
                case "SE": return "Sweden"
                case "CH": return "Switzerland"
                case "TN": return "Tunisia"
                case "TR": return "Turkey"
                case "UK": return "United Kingdom"
            }
        },
        jobroleNameForID(jobroleID) {
            switch (jobroleID) {
                case "CM": return "CUPRA Master"
                case "CE": return "CUPRA Expert"
                case "SM": return "Sales Manager"
                case "SEM": return "Service Manager"
                case "MT": return "Master Technician"
            }
        }
    }
}
</script>

<style>
body {
    padding: 0px;
}
#container {
    width: 100vw;
    height: 100vh;
    background-color: transparent;
}
#content {
    width: 100%;
    height: 100%;
    position: absolute;
    /* background-color: red; */
    left: 0;
    right: 0;
    top: 320px;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
}
.rankContainer {
    max-width: 100%;
    height: 800px;
}
.ranking {
    font-family: "Cupra-Regular" !important;
    font-size: 30px !important;
    color: white !important;
    width: 600px;
    height: 800px;
    margin: auto;
}
#header {
    font-family: "Cupra-Bold" !important;
    font-size: 100px !important;
    color: white !important;
}
.info {
    font-family: "Cupra-Regular" !important;
    font-size: 40px !important;
    color: white !important;
}
.bubble {
    font-family: "Cupra-Bold" !important;
    font-size: 35px !important;
    background-color: rgba(1, 1, 1, 0.4);
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    vertical-align: middle;
    border-radius: 35px;
}
.textblock {
    font-family: "Cupra-Medium" !important;
    font-size: 50px !important;
    height: 70px;
    line-height: 70px;
    vertical-align: middle;
}
.textblock2 {
    font-family: "Cupra-Medium" !important;
    /* font-size: 50px !important; */
    font-size: 2em !important;
    height: 70px;
    line-height: 70px;
    vertical-align: middle;
    text-align: center;
}
.allCorrect {
    font-family: "Cupra-Regular" !important;
    font-size: 150px !important;
    height: 300px;
    line-height: 300px;
    text-align: center;
    vertical-align: middle;
}
.counter {
    font-family: "Cupra-Bold" !important;
    font-size: 150px !important;
    height: 300px;
    color: white;
    line-height: 300px;
    text-align: center;
    vertical-align: middle;
}
.counter2 {
    font-family: "Cupra-Bold" !important;
    font-size: 250px !important;
    height: 300px;
    color: white;
    line-height: 300px;
    text-align: center;
    vertical-align: middle;
}
</style>