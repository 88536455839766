<template>
    <div style="margin: 0px">
        <b-button @click="resetAnswers()">Delete all answers</b-button><br /><br />
        <b-button @click="resetStationStatus()">Reset Station Status</b-button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        resetAnswers() {
            if(confirm('Confirm')) {
                this.kmo_resetQuizAnswers().then(() => {
                    console.log("resetAnswers")
                })
            }
        },
        resetStationStatus() {
            if(confirm('Confirm')) {
                this.kmo_resetStationStatus().then(() => {
                    console.log("resetStationStatus")
                })
            }
        },
        // changeSettings() {
        //     this.changeRainMode()
        // },
        // changeRainMode() {
        //     if (this.rainMode != this.rainModeInit) {
        //         if (this.rainMode == true) {
        //             this.$bvModal.msgBoxConfirm("Enabling Rain-Mode will remove the Scooter- and eBike-Experience from the smart MEX App.", {
        //                 title: 'Please Confirm',
        //                 size: 'sm',
        //                 buttonSize: 'lg',
        //                 okVariant: 'danger',
        //                 okTitle: 'ENABLE',
        //                 cancelTitle: 'Cancel',
        //                 hideHeaderClose: true,
        //                 centered: true
        //                 })
        //             .then(value => {
        //                 if (value == true) {
        //                     this.kmo_setRainMode(this.rainMode).then((response) => {
        //                         console.log(response)
        //                         this.$bvToast.toast("Rain-Mode successfuly enabled!", {
        //                             title: "Info",
        //                             autoHideDelay: 5000,
        //                             append: true
        //                         })
        //                     });
        //                 } else {
        //                     this.refreshSettings();
        //                 }
        //             })
        //             .catch(err => {
        //                 console.log(err)
        //             });
        //         } else {
        //             this.kmo_setRainMode(this.rainMode).then((response) => {
        //                 console.log(response)
        //                 var message = "disabled"
        //                 if (this.rainMode == "1") {
        //                     message = "enabled"
        //                 }
        //                 this.$bvToast.toast("Rain-Mode successfuly " + message + "!", {
        //                     title: "Info",
        //                     autoHideDelay: 5000,
        //                     append: true
        //                 })
        //             });
        //         }
        //         this.rainModeInit = this.rainMode
        //     }
        // }
    },
    created() {
        // this.refreshSettings();
    }
}
</script>

<style>

</style>