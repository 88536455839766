<template>
    <div>

        <b-modal
            ref="editModal"
            body-bg-variant="light"
            size="xl"
            hide-footer
            centered
        >
            <b-alert :show="alertShow" :variant="alertVariant">{{alertText}}</b-alert>

            <template #modal-header="{ }">
                <h4>{{selectedTextKey}}</h4>
            </template>

            <b-form>
                <!-- <label><strong>Deutsch</strong></label>
                <b-form-textarea
                    id="german"
                    v-model="selected_de_DE"
                    rows="5"
                    :disabled="editDisabled"
                ></b-form-textarea>
                <br /> -->
                
                <label><strong>Englisch</strong></label>
                <b-form-textarea
                    id="englisch"
                    v-model="selected_en_EN"
                    rows="5"
                    :disabled="editDisabled"
                ></b-form-textarea>
                <br />
                
                <b-button class="button-secondary" style="float: left" @click="hideModal" :disabled="editDisabled">Abbrechen</b-button>

                <b-button class="button-primary" style="float: right" @click="save" :disabled="editDisabled">Speichern</b-button>
                
            </b-form>

        </b-modal>
        
        <b-form-input
            v-model="filter"
            type="search"
            size="lg"
            placeholder="Volltextsuche"
        ></b-form-input><br >
        <b-table class="textTable" :items="databaseLanguages" :fields="fields" :filter="filter" responsive bordered outlined stacked="lg">

            <template #cell(textKey)="data">
                <b>{{ data.value }}</b>
            </template>

            <template #cell(edit)="row">
                <b-button class="button-primary" style="float: right" size="sm" @click="editText(row.item)"><b-icon icon="pencil-square"></b-icon></b-button>
            </template>

        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            databaseLanguages: [],
            filter: null,
            selectedTextKey: null,
            selectedTextGroup: null,
            selected_de_DE: null,
            selected_en_EN: null,
            alertShow: false,
            alertText: "",
            alertVariant: "",
            editDisabled: false,
            fields: [
                { key: 'textGroup', label: 'Group', thStyle: { width: "130px" }},
                { key: 'textKey', label: 'TextKey', thStyle: { width: "300px" }},
                // { key: 'de_DE', label: 'German' },
                { key: 'en_EN', label: 'English' },
                { key: 'edit', label: 'Edit', thStyle: { width: "60px" }},
            ]
        }
    },
    methods: {
        refreshLanguages() {
            this.kmo_refreshLanguages().then(() => {
                this.databaseLanguages = this.$store.databaseLanguages
                this.databaseLanguages.forEach(text => {
                    if (text['textGroup'] == "global") { text['textGroup'] = "Global" }
                    if (text['textGroup'] == "drive") { text['textGroup'] = "Drive" }
                    if (text['textGroup'] == "web") { text['textGroup'] = "Web-Frontend" }
                });
            })
        },
        editText(text) {
            this.selectedTextKey = text['textKey']
            this.selectedTextGroup = text['textGroup']
            // this.selected_de_DE = text['de_DE']
            this.selected_en_EN = text['en_EN']
            this.$refs['editModal'].show()
        },
        hideModal() {
            this.$refs['editModal'].hide()
        },
        save() {
            if (this.selected_en_EN) {
                this.editDisabled = true
                this.kmo_saveTextUpdate(this.selectedTextKey, this.selected_de_DE, this.selected_en_EN).then((result) => {
                    if (result[0]['affectedRows'] == 1) {
                        this.alertText = "Erfolgreich gespeichert!"
                        this.alertVariant = "success"
                        this.alertShow = true
                        setTimeout(() => {
                            this.$refs['editModal'].hide()
                            this.alertShow = false
                            this.editDisabled = false
                        }, 2000)
                    } else {
                        this.alertText = "Fehler beim Speichern!"
                        this.alertVariant = "danger"
                        this.alertShow = true
                        this.editDisabled = false
                    }
                    this.refreshLanguages()
                })
            } else {
                this.alertText = "Leere Felder sind nicht erlaubt!"
                this.alertVariant = "danger"
                this.alertShow = true
            }
        },
    },
    created() {
        this.refreshLanguages()
    }
}
</script>

<style scoped>
.button-primary {
    background-color: #002c5f !important;
    border-color: #002c5f !important;
}
.textTable {
    background-color: white;
}
</style>